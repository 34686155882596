import PublicRepoStore from "./repoBase.js";
import store from "@/store";

const eventRepo = new PublicRepoStore("ApiEventRepo", 240);

eventRepo.mutations.SET_EVENT = (state, eventId) => () => {
  state.eventId = eventId;
};

eventRepo.actions.loadEvent =
  ({ commit, getters }, eventId) =>
  () => {
    const event = getters.repoEvents.getById(eventId);

    for (const service of event.services) {
      store.dispatch("repoServices/upsert", service.Id);
      // Event doesn't load the full menu, the service only does that
    }

    commit("SET_EVENT", eventId);

    return event;
  };

eventRepo.getters.getByIdWithQuery = () => async (id) => {
  const response =
    await store.state.apiPublic.client.endpoints.events.getbyIdWithQuery(
      {
        includeServices: true,
        includeOutlets: true,
      },
      id
    );

  response.data.data.services = response.data.data.services.map((service) => {
    try {
      service.outlet = response.data.data.outlets.filter(
        (outlet) => outlet.id == service.outlet.id
      )[0];
    } catch {
      service.outlet.nameDisplay = service.outlet.name;
    }

    return service;
  });

  return response.data.data;
};

export default eventRepo;
