import { createStore } from "vuex";

import linkMode from "@/store/linkMode";
import network from "@/store/network";
import apiPublic from "@/store/public/apiPublic";
import cart from "@/store/public/cart";
import collectionTimes from "@/store/public/jobs/collectionTimes";

import repoCharities from "@/store/public/repos/repoCharities";
import repoItems from "@/store/public/repos/repoItems";
import repoEvents from "@/store/public/repos/repoEvents";
import repoMenus from "@/store/public/repos/repoMenus";
import repoOrders from "@/store/public/repos/repoOrders";
import repoOutlets from "@/store/public/repos/repoOutlets";
import repoPromotions from "@/store/public/repos/repoPromotions";
import repoServices from "@/store/public/repos/repoServices";
import repoServiceItems from "@/store/public/repos/repoServiceItems";
import repoServiceComponents from "@/store/public/repos/repoServiceComponents";
import repoVendors from "@/store/public/repos/repoVendors";
import repoComponents from "@/store/public/repos/repoComponents";
import repoItemComponents from "@/store/public/repos/repoItemComponents";
import repoAllergens from "@/store/public/repos/repoAllergens";

export default createStore({
  state: {
    locale: "en-GB",
    currency: "GBP",
    timezone: "Europe/London",
  },
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    linkMode,
    network,
    apiPublic,
    cart,
    collectionTimes,
    repoCharities,
    repoItems,
    repoEvents,
    repoMenus,
    repoOrders,
    repoOutlets,
    repoPromotions,
    repoServices,
    repoServiceItems,
    repoServiceComponents,
    repoVendors,
    repoComponents,
    repoItemComponents,
    repoAllergens,
  },
});
