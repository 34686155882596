import PublicRepoStore from "./repoBase.js";
import store from "@/store";

const serviceRepoStore = new PublicRepoStore("ApiServiceRepo", 240);

serviceRepoStore.mutations.SET_SERVICE = (state, serviceId) => () => {
  state.serviceId = serviceId;
};

serviceRepoStore.actions.loadService = ({ commit }, serviceId) => {
  if (serviceId == null || isNaN(serviceId) || serviceId <= 0) {
    // We just ignore any requests to load 0 or undefined or NaN

    return Promise.reject(
      "Cannot load a service without providing a service ID."
    );
  }

  return store.state.repoServices.repo
    .getById(serviceId)
    .then((service) => {
      return store.dispatch("cart/selectService", serviceId).then(() => {
        commit("SET_SERVICE", serviceId);

        return service;
      });
    })
    .catch((error) => {
      window.log.error(error);
    });
};

serviceRepoStore.getters.getByIdWithQuery = () => async (id, query) => {
  try {
    const response =
      await store.state.apiPublic.client.endpoints.services.getbyIdWithQuery(
        query,
        id
      );
    return response.data.data;
  } catch (error) {
    window.log.error(error);
  }
};

export default serviceRepoStore;
