<template>
  <span
    v-if="!this.inline"
    class="icon-wrapper"
    :class="{ 'icon-wrapper_hover': tooltip }">
    <span
      :aria-hidden="!title"
      :aria-label="title"
      :title="title"
      class="mdi-icon"
      role="img"
      v-bind="$attrs"
      @click="$emit('click', $event)">
      <svg :fill="fillColor" :width="size" :height="size" viewBox="0 0 24 24">
        <path :d="path" v-if="path != null" />
        <path :d="fallbackPath" v-else />
      </svg>
    </span>
    <div v-if="tooltip" class="mdi-icon__tooltip">
      {{ tooltip }}
    </div>
  </span>
  <svg
    v-else
    :fill="fillColor"
    :width="size"
    :height="size"
    class="icon-inline"
    viewBox="0 0 24 24">
    <path :d="svgPath" v-if="path != null" />
    <path :d="fallbackPath" v-else />
  </svg>
</template>

<script lang="js">
  export default {
    name: "ImageIcon",
    data() {
      return {
        fallbackPath: "M3,3V21H21V3",
      };
    },
    props: {
      path: {
        type: String,
      },
      title: {
        type: String,
      },
      fillColor: {
        type: String,
        default: "currentColor",
      },
      size: {
        type: Number,
        default: 24,
      },
      tooltip: {
        type: String,
      },
      inline: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon-wrapper {
    position: relative;

    @media screen and (max-width: 480px) {
      position: initial;
    }

    &_hover {
      cursor: pointer;

      .mdi-icon {
        display: block !important;
      }
    }

    .mdi-icon__tooltip {
      opacity: 0;
      position: absolute;
      bottom: 100%;
      max-width: 240px;
      width: max-content;
      background: #aaa;
      padding: 14px;
      color: white;
      border-radius: 5px;
      display: none;

      @media screen and (max-width: 480px) {
        left: auto;
        min-width: fit-content;
        bottom: 20px;
      }
    }

    &:hover {
      .mdi-icon__tooltip {
        display: block;
        opacity: 1;
        transition: all 0.5s;
      }
    }
  }
  .mdi-icon {
    line-height: 0;
  }

  .icon-inline {
    position: relative;
    top: 4px;
  }
</style>
