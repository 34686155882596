import { LocalDateTime, Duration } from "@js-joda/core";
import store from "@/store";

export default {
  namespaced: true,

  state: {
    job: null,
    lastUpdated: null,
    cacheDuration: Duration.ofMinutes(0),
    serviceId: null,
    orderId: null,
    cartId: null,

    slots: [],
  },

  actions: {
    startTask({ commit }, params) {
      commit("START_TASK", params);
    },

    stopTask({ commit }) {
      commit("STOP_TASK");
    },

    cachedUpdate({ commit }) {
      const now = LocalDateTime.now();
      const cacheUntil = this.lastUpdated?.plus(this.cacheDuration);

      if (cacheUntil == null || now.isAfter(cacheUntil)) {
        commit("UPDATE", now);
      }
    },

    forceUpdate({ commit }) {
      const now = LocalDateTime.now();

      commit("UPDATE", now);
    },
  },

  mutations: {
    START_TASK(state, params) {
      state.serviceId = params.serviceId;
      state.cartId = params.cartId;

      store.dispatch("collectionTimes/cachedUpdate");

      state.job = window.setInterval(() => {
        store.dispatch("collectionTimes/forceUpdate");
      }, 5000);
    },

    async UPDATE(state, now) {
      const apiPublic = store.state.apiPublic.client;
      const response = await apiPublic.endpoints.services.getSlots(
        state.serviceId,
        state.cartId
      );

      state.slots = response.data.data;

      state.lastUpdated = now;
    },

    STOP_TASK(state) {
      if (state.job != null) {
        window.clearInterval(state.job);
      }

      state.job = null;
    },
  },
};
