import { ApiCart } from "@tucktrucks/platform-base-public";
import { useToast } from "vue-toastification";
import store from "@/store";
import Queue from "queue-promise";

export const cartDataGet = (serviceId) => {
  const cartDataKey = `s${serviceId}`;
  const cartDataString = window.localStorage.getItem(cartDataKey);
  let cartDataObject;

  try {
    cartDataObject = JSON.parse(cartDataString);
  } catch {
    cartDataObject = { id: 0 };
  }

  return cartDataObject;
};

export const cartDataCreate = (serviceId, cartId, cartKey) => {
  const cartDataKey = `s${serviceId}`;
  const cartData = {
    id: cartId,
    key: cartKey,
  };

  window.localStorage.setItem(cartDataKey, JSON.stringify(cartData));

  return cartData;
};

export const cartDataRemove = (serviceId) => {
  const cartDataKey = `s${serviceId}`;

  window.localStorage.removeItem(cartDataKey);
};

/**
 * cart.js
 */
export default {
  namespaced: true,

  state: {
    serviceId: 0,
    orderId: 0,
    cartId: 0,
    cartKey: null,
    locked: false,
    summary: {},
    customer: {},
    items: {},
    queue: new Queue({ concurrent: 1, interval: 1000 }),
    queueEnd: true,
    artChannelUpdating: false,
  },

  mutations: {
    UPDATE_SERVICE_ID(state, serviceId) {
      state.serviceId = serviceId;
    },

    UPDATE_CART(state, cart) {
      state.cartId = cart.id;
      state.cartKey = cart.key;

      store.dispatch("cart/save");
    },

    /*
     * The summary is the cart pricing, plus is used to extract further information about the cart.
     */
    UPDATE_SUMMARY(state, summary) {
      state.summary = summary;
    },

    QUEUE_START(state) {
      state.queueEnd = false;
    },

    ENQUEUE(state, payload) {
      state.queue.enqueue(payload);
    },

    QUEUE_END(state) {
      state.queueEnd = true;
    },

    CART_CHANNEL_UPDATING(state, payload) {
      state.cartChannelUpdating = payload;
    },
  },

  actions: {
    /*
     * Change which service we are currently attending.
     */
    selectService({ state, commit }, serviceId) {
      serviceId = parseInt(serviceId ?? 0);
      if (state.serviceId == serviceId) {
        return;
      }

      if (serviceId == null || isNaN(serviceId) || serviceId <= 0) {
        //window.log.info("[🛒] Switching to no selected service.");
        commit("UPDATE_SERVICE_ID", 0);
        commit("UPDATE_CART", { cartId: 0, cartKey: null });

        return;
      }

      window.log.info("[🛒] Switching to service " + serviceId);
      commit("UPDATE_SERVICE_ID", serviceId);

      var failed = false;
      var cart;
      var cartId;
      var cartKey;

      try {
        cart = cartDataGet(serviceId);

        cartId = cart.id;
        cartKey = cart.key;

        if (cartId == null || isNaN(cartId) || cartId <= 0 || !cartKey) {
          failed = true;
        }
      } catch {
        failed = true;
      }

      if (failed) {
        store.dispatch("cart/createCart");

        return;
      } else {
        commit("UPDATE_CART", cart);

        window.log.info(`[🛒] Found existing cart for service ${serviceId}.`);
      }

      commit("ENQUEUE", async () => {
        await store.state.apiPublic.client.endpoints.cartStatus
          .validateService(cartId, cartKey, state.serviceId)
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.data.data;
              default:
                return "NotValid";
            }
          })
          .then((data) => {
            if (data === "NotValid") {
              store.dispatch("cart/createCart");
            } else {
              commit("UPDATE_SUMMARY", data);
            }
          });
      });
    },

    /*
     * Saves the order to local storage for this service
     */
    save({ state }) {
      cartDataCreate(state.serviceId, state.cartId, state.cartKey);
    },

    async createCart({ state, commit }) {
      commit("UPDATE_CART", { id: 0, key: null });
      commit("UPDATE_SUMMARY", {});

      const serviceId = state.serviceId;

      if (serviceId == null || isNaN(serviceId) || serviceId <= 0) {
        window.log.error(
          "[🛒] Tried to create an order without providing a service id."
        );
        return;
      }

      const service = await store.getters["repoServices/getById"](serviceId);
      const channel =
        service.channels.filter((c) => c.name === "ClickCollectAsap").length > 0
          ? "ClickCollectAsap"
          : service.channels.filter((c) => c.name === "ClickCollect").length > 0
          ? "ClickCollect"
          : null;

      if (channel == null) {
        //store.dispatch("cart/clear");
        return;
      }

      const cart = new ApiCart({
        serviceId: serviceId,
        status: "Created",
        channel: channel,
        method: process.env.VUE_APP_ORDER_METHOD,
      });

      commit("ENQUEUE", async () => {
        return store.state.apiPublic.client.endpoints.carts
          .create(cart)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            window.log.info(
              `[🛒] New ${channel} cart created for service ${serviceId}. (${data.data.id}:${data.meta["cart-key"]})`,
              data
            );

            store.dispatch("cart/save");

            commit("UPDATE_CART", {
              id: data.data.id,
              key: data.meta["cart-key"],
            });
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to create order.", error);
          });
      });
    },

    clear() {
      window.sessionStorage.setItem("cus", null);
      cartDataRemove(this.serviceId);

      store.dispatch("cart/createCart");
    },

    /*
     * Updates or inserts an item into the platform
     */
    upsertItem({ state, commit, dispatch }, item /* ApiOrderItem */) {
      commit("ENQUEUE", async () => {
        await dispatch("validateCart");

        return store.state.apiPublic.client.endpoints.cartItems
          .upsert(item, state.cartId, state.cartKey)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }

            return Promise.reject("Could not upsert item.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to add item to order.", error);

            const toast = useToast();
            toast.error("Could not add to cart.");
          });
      });
    },

    /*
     * Change the amount of an item
     */
    modifyItemAmount({ state, commit, dispatch }, payload) {
      commit("ENQUEUE", async () => {
        await dispatch("validateCart");
        await store.state.apiPublic.client.endpoints.cartItems
          .changeQuantity(
            { amount: payload.delta, hash: payload.item.hash },
            state.cartId,
            state.cartKey
          )

          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }

            return Promise.reject("Could not modify item amount.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to modify item amount.", error);

            const toast = useToast();
            toast.error("Could not modify amount.");
          });
      });
    },

    /*
     * Updates or inserts a donation into the platform
     */
    upsertDonation({ state, commit }, donation /* ApiCharityDonation */) {
      commit("ENQUEUE", () => {
        return store.state.apiPublic.client.endpoints.cartDonations
          .update(donation, state.cartId, state.cartKey)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }

            return Promise.reject("Could not upsert donation.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to add donation to order.", error);

            const toast = useToast();
            toast.error("Could not add donation.");
          });
      });
    },

    /*
     * Updates or inserts a voucher into the platform
     */
    upsertVoucher({ state, commit }, voucherCode /* string */) {
      if (voucherCode == null || voucherCode == "") {
        return "no voucher code provided";
      }
      commit("ENQUEUE", () => {
        return store.state.apiPublic.client.endpoints.paymentsPromotion
          .createIntent(voucherCode, state.cartId, state.cartKey)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }
            return Promise.reject({
              voucherCode,
              cartId: state.cartId,
              cartKey: state.cartKey,
              response,
            });
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to add voucher to order.", error);

            const toast = useToast();
            toast.error("Could not add voucher.");
            return { error };
          });
      });
    },

    updateEatOnPremises({ state, commit }, eatOnPremises) {
      return store.state.apiPublic.client.endpoints.carts
        .updateEatonPremises(eatOnPremises, state.cartId, state.cartKey)
        .then((response) => {
          if (response.status >= 200 && response.status <= 204) {
            return response.data.data;
          }

          return Promise.reject(
            `Could not set cart to ${eatOnPremises ? "Eat In" : "Take Out"}.`
          );
        })
        .then((data) => {
          commit("UPDATE_SUMMARY", data);

          return eatOnPremises;
        })
        .catch((error) => {
          window.log.error(
            `[🛒] Could not set cart to ${
              eatOnPremises ? "Eat In" : "Take Out"
            }.`,
            error
          );

          const toast = useToast();
          toast.error(
            `Could not set cart to ${eatOnPremises ? "Eat In" : "Take Out"}.`
          );
        });
    },

    // Validate Cart
    async validateCart({ state, commit }) {
      let cartValid = true;

      if (state.cartId == null || state.cartId == 0 || state.cartKey == null) {
        return false;
      }

      return store.state.apiPublic.client.endpoints.cartStatus
        .validate(state.cartId, state.cartKey)
        .then((response) => {
          commit("UPDATE_SUMMARY", response.data.data);
          return cartValid;
        })
        .catch((err) => {
          useToast().error("Error validating cart");
          window.log.error(err);
          return false;
        });
    },

    deleteDonation({ state, commit }, charityId /* number */) {
      commit("ENQUEUE", () => {
        return store.state.apiPublic.client.endpoints.orders
          .deleteDonation(state.orderId, charityId)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }

            return Promise.reject("Could not delete donation.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to delete donation.", error);

            const toast = useToast();
            toast.error("Could not remove donation.");
          });
      });
    },

    deleteVoucher({ state, commit }) {
      commit("ENQUEUE", () => {
        return store.state.apiPublic.client.endpoints.paymentsPromotion
          .deleteIntents(state.cartId, state.cartKey)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }

            return Promise.reject("Could not delete voucher.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to delete voucher.", error);

            const toast = useToast();
            toast.error("Could not remove voucher.");
          });
      });
    },

    updateChannel({ state, commit }, channel /* string */) {
      commit("CART_CHANNEL_UPDATING", true);
      commit("ENQUEUE", () => {
        return store.state.apiPublic.client.endpoints.carts
          .updateChannel(channel, state.cartId, state.cartKey)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }

            return Promise.reject("Could not update channel.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to update cart channel.", error);

            const toast = useToast();
            toast.error("Could not set cart channel.");
          })
          .finally(() => {
            commit("CART_CHANNEL_UPDATING", false);
          });
      });
    },

    updateSelectedSlot({ state, commit }, selectedSlot /* LocalTime */) {
      commit("ENQUEUE", () => {
        if (selectedSlot?.range?.timeEnd != null) {
          selectedSlot = selectedSlot.range.timeEnd;
        }

        return store.state.apiPublic.client.endpoints.carts
          .updateAdvertisedTime(selectedSlot, state.cartId, state.cartKey)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }

            return Promise.reject("Could not update selected slot.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to update time slot.", error);

            const toast = useToast();
            toast.error("Could not set time slot.");
          });
      });
    },

    updateDeliveryDropOff({ state, commit }, dropOffId) {
      commit("ENQUEUE", () => {
        return store.state.apiPublic.client.endpoints.carts
          .updateDropoffPoint(dropOffId, state.cartId, state.cartKey)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }

            return Promise.reject("Could not update delivery drop off.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to update drop off point.", error);

            const toast = useToast();
            toast.error("Could not set drop off point.");
          });
      });
    },

    updateTableNumber({ commit, state }, number) {
      commit("ENQUEUE", () => {
        return store.state.apiPublic.client.endpoints.cartTables
          .update(number, state.cartId, state.cartKey)
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return response.data.data;
            }

            return Promise.reject("Could not update table number.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to update table number.", error);

            const toast = useToast();
            toast.error("Could not set table number.");
          });
      });
    },

    updateCustomer({ state, commit }, payload) {
      commit("ENQUEUE", () => {
        return store.state.apiPublic.client.endpoints.cartCustomer
          .update(payload, state.cartId, state.cartKey)
          .then((response) => {
            if (response.status >= 200 && response.status <= 204) {
              return response.data.data;
            }

            return Promise.reject("Could not update customer.");
          })
          .then((data) => {
            commit("UPDATE_SUMMARY", data);
          })
          .catch((error) => {
            window.log.error("[🛒] Failed to update customer.", error);

            const toast = useToast();
            toast.error("Could not set customer.");
          });
      });
    },

    queueEndListener({ commit, state }) {
      state.queue.on("start", () => {
        commit("QUEUE_START");
      });
      state.queue.on("end", () => {
        commit("QUEUE_END");
      });
    },
  },

  getters: {
    get: (state) => state,
    getLock: (state) => state.locked,
    getServiceId: (state) => state.serviceId,
    getOrderId: (state) => state.orderId,
    getCartId: (state) => state.cartId,
    getCartKey: (state) => state.cartKey,
    getSummary: (state) => state.summary,
    getTotal: (state) => state.summary?.total ?? 0,
    getItems: (state) => state.summary?.items ?? [],
    getItemsCount: (state) => state.summary?.items?.length ?? 0,
    getItemsAmount: (state) =>
      state.summary?.items?.reduce((sum, item) => sum + item.amount, 0),
    getChannel: (state) => state.channel,
    getComponentNames: (state) =>
      state.summary?.items?.map(
        ({ componentAlterations }) => componentAlterations.name
      ),
    getCartComponents: (state) =>
      state.summary?.items?.map(
        ({ componentAlterations }) => componentAlterations
      ),
    getQueueIsEnd: (state) => state.queueEnd,
    getIfCartChannelUpdating: (state) => state.cartChannelUpdating,
  },

  modules: {},
};
