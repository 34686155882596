<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  import store from "./store";

  export default {
    data() {
      return {};
    },

    async created() {
      await store.getters["repoAllergens/getAll"]();
    },

    errorCaptured(err, vm, info) {
      window.log.error(
        `An unhandled exception was caught on the main App component.\n${err?.message}\n`,
        {
          err,
          vm,
          info,
        }
      );

      return false;
    },
  };
</script>

<style></style>
