import store from "@/store";

export default {
  namespaced: true,

  state: {
    networkError: false,
    networkErrorFirst: null,
    networkErrorCount: 0,
    job: null,
  },

  actions: {
    onSuccess({ commit, state }, response) {
      if (state.networkError) {
        commit("CLEAR_NETWORK_ERROR", response);
      }
    },

    onError({ commit }, error) {
      if (
        error?.response?.status != null &&
        error.response.status >= 500 &&
        error.response.status <= 599
      ) {
        commit("ON_SERVER_ERROR", error);
      } else {
        commit("ON_NETWORK_ERROR", error);
      }
    },
  },

  mutations: {
    CLEAR_NETWORK_ERROR(state) {
      const recovered = new Date();

      try {
        window.log.info(
          `[API.Network] Recovered in ${
            recovered - state.networkErrorFirst.when
          }ms.`
        );
      } catch {
        window.log.info("[API.Network] Recovered");
      }

      state.networkError = false;
      state.networkErrorFirst = null;
      state.networkErrorCount = 0;

      if (state.job != null) {
        window.clearInterval(state.job);
        state.job = null;
      }
    },

    ON_SERVER_ERROR(state, error) {
      error.stack = "REDACTED";
      error.when = new Date();

      // window.log.error(
      //   `[API.Server] Server error. ${error?.response?.status} ${error?.response?.statusText}`,
      //   error
      // );
    },

    ON_NETWORK_ERROR(state, error) {
      error ??= {};
      error.stack = "REDACTED";
      error.when = new Date();

      state.networkError = true;

      if (state.networkErrorFirst == null) {
        state.networkErrorFirst = error;
      }

      state.networkErrorCount += 1;

      if (state.job == null)
        state.job = window.setInterval(() => {
          store.state.apiPublic.client
            .isApiAvailable()
            .then((response) => {
              store.dispatch("network/onSuccess", response);

              window.clearInterval(state.job);
              state.job = null;
            })
            .catch((error) => {
              store.dispatch("network/onError", error);
            });
        }, 20000);

      window.log.warn("[API.Network]", error);
    },
  },

  getters: {
    networkError: (state) => () => state.networkError,
    networkLostAt: (state) => () => state.networkErrorFirst?.when,
  },
};
