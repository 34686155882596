export default {
  namespaced: true,

  // No longer using state as the session storage becomes the state, retaining the store pattern.
  state: {},

  actions: {
    set({ commit }, params) {
      commit("SET", params);
    },
  },
  mutations: {
    SET(state, params) {
      switch (params.mode) {
        case "vendor":
        case "venue":
          window.sessionStorage.setItem("lm", params.mode);
          window.sessionStorage.setItem("lr", params.ref);
          break;
        default:
          window.sessionStorage.setItem("lm", "default");
          break;
      }
    },
  },
  getters: {
    getMode: () => {
      return window.sessionStorage.getItem("lm") ?? "default";
    },

    getRef: () => {
      return window.sessionStorage.getItem("lr") ?? "";
    },
  },
};
