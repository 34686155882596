import {
  DateTimeFormatter,
  LocalDateTime,
  LocalTime,
  LocalDate,
} from "@js-joda/core";
require("@js-joda/timezone");
import store from "@/store";
// import { humanize, transform, toTitleCase } from "@alduino/humanizer/string";

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        getCdnFile(file) {
          return process.env.VUE_APP_CDN + file;
        },

        getCdnImage(apiImageVersion, usage, size, format) {
          return `${process.env.VUE_APP_CDN}/images/${apiImageVersion.folder}/${apiImageVersion.image}/${apiImageVersion.version}/${usage}-${size.width}x${size.height}.${format}`;
        },

        formatMoney(money) {
          if (money == null || isNaN(money)) {
            return "";
          }

          return new Intl.NumberFormat(store.state.locale, {
            style: "currency",
            currency: store.state.currency,
          }).format(money);
        },

        formatDateTime(dateTime, format = "dd/MM/yyyy HH:mm") {
          if (dateTime == null) {
            return "--/--/---- --:--";
          }

          const formatter = DateTimeFormatter.ofPattern(format);
          const localDateTime = LocalDateTime.parse(dateTime);
          return localDateTime.atZone(store.state.timezone).format(formatter);
        },

        formatDate(date, format = "dd/MM/yyyy") {
          if (date == null) {
            return "--/--/----";
          }

          const localDate = LocalDate.parse(date);

          if (format == "dddd") {
            // const dayOfWeek = localDate.dayOfWeek().toString().toLowerCase(); // This won't humanize unless both toString and toLowerCase are used (???)
            // return transform(humanize(dayOfWeek), toTitleCase);
            const dayOfWeek = localDate.dayOfWeek().name();
            return dayOfWeek.slice(0, 3);
          }

          const formatter = DateTimeFormatter.ofPattern(format);
          return localDate.format(formatter);
        },

        formatTime(time, format = "HH:mm") {
          if (time == null) {
            return "--:--";
          }

          const formatter = DateTimeFormatter.ofPattern(format);
          const localTime = LocalTime.parse(time);
          return localTime.format(formatter);
        },

        formatDay(date) {
          if (date === null) {
            return "-";
          }

          const dateName = new Date(date).toLocaleString("en-us", {
            weekday: "short",
          });
          return dateName;
        },
      },
    });
  },
};
