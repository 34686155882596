import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

document.title = "Tuck Trucks";

const routes = [
  {
    path: "/",
    component: () => import("@/views/Main.vue"),
    children: [
      {
        path: "/",
        meta: {
          cartView: false,
          mode: "upcoming",
        },
        component: () => import("@/views/VenueList.vue"),
      },
      {
        path: "/venues",
        name: "venues",
        meta: {
          cartView: false,
          mode: "upcoming",
        },
        component: () => import("@/views/VenueList.vue"),
      },
      {
        path: "/venues",
        name: "venues",
        meta: {
          cartView: false,
          mode: "upcoming",
        },
        component: () => import("@/views/VenueList.vue"),
      },
      {
        path: "/vendors",
        name: "vendors",
        meta: {
          cartView: false,
          mode: "upcoming",
        },
        component: () => import("@/views/VendorList.vue"),
      },
      {
        path: "/venue/:venueCode",
        name: "venue",
        meta: {
          cartView: false,
          mode: "upcoming",
        },
        props: true,
        component: () => import("@/views/VenueOutletList.vue"),
      },
      {
        path: "/vendor/:vendorCode",
        name: "vendor",
        meta: {
          cartView: false,
          mode: "upcoming",
        },
        props: true,
        component: () => import("@/views/VendorOutletList.vue"),
      },
      {
        path: "/outlet/:outletCode",
        name: "outlet",
        meta: {
          cartView: false,
          mode: "upcoming",
        },
        props: true,
        component: () => import("@/views/VendorOutlet.vue"),
      },
      {
        path: "/service/:serviceId",
        name: "service",
        meta: { cartView: true },
        component: () => import("@/views/ServiceMenu.vue"),
      },
      {
        path: "/service/:serviceId/:cartId/:cartKey/checkout",
        name: "checkout",
        meta: { cartView: false },
        component: () => import("@/views/Checkout.vue"),
      },
      {
        path: "/order/:orderId/:secret",
        name: "order-status",
        meta: { cartView: false },
        component: () => import("@/views/OrderStatus.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/Error.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // Allow hash links to go through unmodified
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }

    if (process.env.VUE_APP_LOG_ROUTES != null) {
      window.log.info(
        `[🧭] ${from.path} ➡️ ${to.path} [${savedPosition?.left ?? 0}, ${
          savedPosition?.top ?? 0
        }]`
      );
    }

    // always scroll to top
    return { top: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // If a link mode has been provided, store it, along with any given reference.
  // This is used to limit

  if (to.query?.lm) {
    const linkMode = {
      mode: to.query?.lm,
      ref: to.query?.lr,
    };

    store.dispatch("linkMode/set", linkMode);

    switch (linkMode.mode) {
      default:
        to.query.lm = null;
        to.query.lr = null;
        router.replace(to.path);
        break;
      case "vendor":
        router.replace({
          name: "vendor",
          params: { vendorCode: linkMode.ref },
        });
        break;
      case "venue":
        router.replace({
          name: "venue",
          params: { venueCode: linkMode.ref },
        });
        break;
    }
  }

  next();
});

export default router;
